import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import AdminContainer from '../../../../layout/admin/container';
import { useBreadcrumbs } from '../../../../../contexts/breadcrumbs-provider';
import Heading from '../../../../layout/admin/heading';
import { getApiRequest } from '../../../../../utils/request';
import { isBrowser, formatDateTime } from '../../../../../utils/helpers';
import Loadable from '../../../../data/loadable';
import AdminTable from '../common/admin-table';
import AutoForm from '../../../../form/auto-form';
import SEO from '../../../../layout/seo';


function formatRow(item) {
  const {id, center, league, email, sent, created_at: created } = item;
  const { name: centerName } = center || { name: 'NA' };
  const { name: leagueName } = league || { name: 'NA' };
  return { 
    email,
    centerName,
    leagueName,
    sent: sent ? 'Yes' : 'No',
    created: formatDateTime(created),
    id,
  };
}

const AdminNotificationRequests = () => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [queryParamsString, setQueryParamsString] = useState(null);
  const { setBreadcrumbs } = useBreadcrumbs();
  useEffect(() => {
    setBreadcrumbs([
      {
        href: '/app/admin/notification-requests',
        label: 'Notification Requests',
      },
    ]);   
  }, []);

  const query = queryString.parse(isBrowser ? window.location.search : '');
  const currentQueryParamsString = queryString.stringify(query || {});

  const loadNotificationRequests = async () => {
    if (!loading) {
      setLoading(true);
    }
    const result = await getApiRequest(`/admin/notification-requests${queryParamsString ? `?${queryParamsString}` : ''}`);
    // setNotification Requests(result.data);
    setResults(result);
    setLoading(false);
  };

  useEffect(() => {
    if (currentQueryParamsString !== queryParamsString) {
      setQueryParamsString(currentQueryParamsString);
    }
  }, [currentQueryParamsString]);


  useEffect(() => {
    if (queryParamsString !== null && queryParamsString === currentQueryParamsString) {
      loadNotificationRequests();
    }
  }, [queryParamsString]);

  const columns = [
    {
      key: 'email',
      label: 'Email',
      isBold: true,
    },
    {
      key: 'leagueName',
      label: 'League',
    },
    {
      key: 'centerName',
      label: 'Center',
    },
    {
      key: 'sent',
      label: 'Is Sent',
    },
    // {
    //   key: 'actions',
    //   label: '',
    //   isRight: true,
    //   render: (row) => (
    //     <Link
    //       to={`/app/admin/notification-requests/${row.id}`}
    //       className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
    //     >
    //       View
    //     </Link>
    //   ),
    // },
  ];


  const baseUrl = '/app/admin/notification-requests';

  const searchForm = {
    type: 'get',
    noContainer: true,
    successRedirect: '/app/admin/notification-requests',
    fields: [
      {
        name: 'email',
        label: 'Email',
        type: 'email',
      },
      {
        name: 'league_id',
        label: 'League',
        type: 'select',
        optionsUrl: '/options/leagues',
      },
      {
        name: 'center_id',
        label: 'Center',
        type: 'select-lookup',
        lookupUrl: '/options/centers/search',
        placeholder: 'Search centers by name',
      },
      {
        name: 'sent',
        label: 'Is Sent',
        type: 'select',
        options: [
          {
            value: '',
            label: 'Select...',
          },
          {
            value: 1,
            label: 'Yes',
          },
          {
            value: 0,
            label: 'No',
          },
        ],
      },
    ],
    submitLabel: 'Search Notification Requests',
  };

  return (
    <AdminContainer>
      <SEO title="Notification requests" />
      <Heading title="Notification Requests" />
      <Loadable loading={loading}>
        <AdminTable results={results} columns={columns} baseUrl={baseUrl} rowFormatter={formatRow} searchTitle="Search Notification Requests" searchForm={<AutoForm {...searchForm} />} />
      </Loadable>
    </AdminContainer>
  );
}

export default AdminNotificationRequests;
